import React from "react";
import {StaticQuery, graphql,Link } from "gatsby";
import * as tracksStyle from "./tracks.module.css"

const Tracks = () => (
    
    <StaticQuery
    query={graphql`
        query{
           allStrapiBlock(filter: {slug: {eq: "polecane-trasy"}}) {
                nodes {
                  title
                  content
                }
              }
        }
    `}
    render={(data) => (
        <div className={tracksStyle.main}>
            <div className="wrapper">
                <h3>{data.allStrapiBlock.nodes['0'].title}</h3>
                <p>{data.allStrapiBlock.nodes['0'].content}</p>
                <Link to ="/polecane-trasy">zobacz</Link>
            </div>
        </div>
        
    )}
    />
);
export default Tracks;
