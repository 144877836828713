import React from "react";
import {StaticQuery, graphql } from "gatsby";
import ServicesComponent from './service.js'
import Slider from 'react-slick';
import * as servicesStyles from "./services.module.css";

var settings = {
    infinite: true,
    arrows:true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${servicesStyles.next}`}
       
        onClick={onClick}
    >{'>'}</div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${servicesStyles.prev}`}
       
        onClick={onClick}
        >{'<'}</div>
    );
  }
const Services = () => (
    
    <StaticQuery
    query={graphql`
        query{
           allStrapiUslugi {
                edges{
                    node {
                        Title
                        Content
                        Cennik {
                            Nazwa
                            Cena
                            id
                          }
                          Photo{
                            publicURL
                            childImageSharp{
                              fluid(maxWidth: 1000, quality: 100){
                                  ...GatsbyImageSharpFluid
                              }
                            }
                        }
                    }
                }
                nodes{
                    Title
                    Content
                }   
            }
           allStrapiBlock(filter: {slug: {eq: "oferta"}}) {
                nodes {
                  title
                  content
                }
              }
        }
    `}
    render={(data) => (
        
        <div className={`${servicesStyles.main}`}>
             <div className="title__wrapper">
                <h3>{data.allStrapiBlock.nodes['0'].content}</h3>
            </div>
            <Slider {...settings}>
                {data.allStrapiUslugi.edges.map((name,i)=>{
                    
                    return (

                        <ServicesComponent service={name} key={`${i}`}/>
                    )
                })}
               </Slider>

            

            
          
        </div>
        
    )}
    />
  
);

export default Services;
