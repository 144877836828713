import React from "react";
import { StaticQuery, graphql } from "gatsby";
import * as specialsStyle from "./specials.module.css"

const Special = () => (
    <StaticQuery
    query={graphql`
        query{
            strapiHomepage {
                Special{
                    Content
                }
                short_about
              }
    }
    `}
    render={(data) => (
        <div>
            <div className={`${specialsStyle.specials} columns`}>
                {data.strapiHomepage.Special.map((name,i) =>
                    <div key={`${i}`} className={`${specialsStyle.single__special} column `}>
                        <div className={`${specialsStyle.wrapper} `}>
                            <p className={specialsStyle.number}>{i >= 10 ? (i+1) : '0' + (i+1)}</p><p className={specialsStyle.text}>{name.Content}</p>
                        </div>
                    </div>
                
                )}
            </div>
            <div className={`${specialsStyle.content}`}>{data.strapiHomepage.short_about}</div>
        </div>
        
    )}
    />
);
export default Special;
