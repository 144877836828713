import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Services from "../components/services";
import Special from "../components/specials";
import Tracks from "../components/tracks";
import Mapa from "../components/map";
import Footer from "../components/footer";





const IndexPage = () => {

  return (
    <Layout >
    <section  className="hero container"><Hero /></section>
    <section id="o-nas" className="specials container "><Special /></section>
    <section id="oferta" className="services container"><Services /></section>
    <section id="polecane-trasy" className="sightseeing container is-fluid p-0"><Tracks /> </section>
    <section id="mapa" className="container"><Mapa /> </section>
    <Footer />
   
  </Layout>
  );
};



export default IndexPage;