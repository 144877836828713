import React from "react";
import * as serviceStyles from "./service.module.css";
import Img from 'gatsby-image'

const ServicesComponent = ({service}) => (
    
    
        <div className={`${serviceStyles.main} columns`}>
            <div className="column">
            <Img
                fluid={service.node.Photo.childImageSharp.fluid}
          />
            </div>
            <div className="column">
                <h4>{service.node.Title}</h4>
                <p className={`${serviceStyles.content}`}>{service.node.Content}</p>
                <ul>
                    {service.node.Cennik.map((entity,i) =>{
                return (
                    <li className={`${serviceStyles.wrapper}`} key={`${i}`}>
                        <p className={`${serviceStyles.number} `}>{i >= 10 ? (i+1) : '0' + (i+1)}</p>
                        <p className={`${serviceStyles.name} `}>{entity.Nazwa}</p>
                        <p className={`${serviceStyles.price} `}>{entity.Cena}zł</p>
                    </li>
                    
                )
                })}
                </ul>
            </div>
            
       
        </div>
      
     
    
        
   );
export default ServicesComponent;
